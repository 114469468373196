import axios from '@axios'

const route = 'manager_room'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    updateRoomBusy(context, room) {
      return axios.put(`${route}/update_room_busy/${room.id}`, room)
    },
    storeItemRemoved(context,itemRemoved){
      
      return axios.post(route+'/store_item_removed', itemRemoved)
    },
    storeTransfRoom(context,transf){
      return axios.post(route+'/store_transf_room/'+transf.accommodation_id, {room_id:transf.room_id,justification:transf.justification})
    },
    printProduct(context,accomodation){
      return axios.post(route+'/print_product/'+accomodation.accommodation_id)
    },
    updateEditCheckout(context,data){
      return axios.post(route + '/update_accommodation_item/' + data.accommodation_id, data.accommodation_item )   
    },
    updateExtraTarrif(context,data){
      return axios.post(route + '/update_extra_item/' + data.accommodation_id, data.extra_items )   
    },
    exportAccommodationPdf(context, room){
      return axios.get(route + '/export_accommodation_pdf/'+room.id,{ responseType: 'blob' })
    },
    getAccommodationObservations(context, id){
      return axios.get(route + '/get_observations/'+id)
    },
    updateObservations(context, data){
      return axios.post(route + '/update_observations', data)
    },
    alertConsumptionLimit(context,data){
      return axios.get(route+'/alert_consumption/' + data)
    },
    cancelCheckin(context,data){
      return axios.post(route+'/cancel_checkin', data)
    },
    updateReserve(context,data){
      return axios.post(route+'/update_reserve_status', data)
    }
    
  },
}
