import axios from '@axios'

const route = 'debug'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    dailyReport(filtro){
      return axios.post(`${route}/daily_report`, filtro)
    },
    exportPdf(context, filter){
      return axios.post(route + '/daily_report/export_pdf',filter,{responseType: 'blob'})
    },
    dailyItensReport(filtro){
      return axios.post(`${route}/itens_report`, filtro)
    },
    exportPdfItens(context, filter){
      return axios.post(route + '/itens_report/export_pdf',filter,{responseType: 'blob'})
    },
   
  },
}
