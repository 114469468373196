import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import app from './app'
import cash from './cash/cash'
import cash_list from './cash/cash_list'
import dashboard from './dashboard/dashboard'
import select from './helper/select'
import login from './login/login'
import busy from './manager_room/busy'
import checkout from './manager_room/checkout'
import manager_room from './manager_room/manager_room'
import reserved from './manager_room/reserved'
import card from './register/card/card'
import company from './register/company/company'
import pdv from './register/pdv/pdv'
import guest from './register/guest/guest'
import pix from './register/pix/pix'
import product from './register/product/product'
import room from './register/room/room'
import rds from './report/rds'
import manager_checkouts from './manager_accommodation/checkouts'
import room_category from './register/room_category/room_category'
import service from './register/service/service'
import supply from './register/supply/supply'
import tariff from './register/tariff/tariff'
import breakfast from './report/breakfast'
import occupied_rooms from './report/occupied_rooms'
import occupation_map from './report/occupation_map'
import advance_payment from './report/advance_payment'
import production_by_company from './report/production_by_company'
import open_account from './report/open_account'
import product_sale from './report/product_sale'
import reserves_today from './report/reserves_today'
import reserve from './reserve/reserve'
import config from './system/config/config'
import role from './system/role/role'
import user from './system/user/user'
import audit from './system/audit/audit'
import occupancy_movemment from './report/occupancy_movemment'
import intern_consumptions from './report/intern_consumptions'
import product_posted from './report/product_posted'
import daily_report from './report/daily_report'
import outstanding from './manager_accommodation/outstanding'
import diary_execution from './system/audit/diary_execution'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    appConfig: appConfigStoreModule,
    login,
    app,
    role,
    user,
    guest,
    pix,
    card,
    select,
    company,
    tariff,
    room_category,
    room,
    manager_room,
    supply,
    product,
    cash,
    reserve,
    checkout,
    reserved,
    cash_list,
    dashboard,
    busy,
    service,
    config,
    occupied_rooms,
    occupation_map,
    breakfast,
    reserves_today,
    manager_checkouts,
    product_sale,
    open_account,
    advance_payment,
    production_by_company,
    pdv,
    occupancy_movemment,
    intern_consumptions,
    rds,
    product_posted,
    daily_report,
    outstanding,
    audit,
    diary_execution,
  },
  plugins:[vuexLocal.plugin]
})
