import axios from '@axios'


const route = 'manager_accommodation'
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    storeOutstanding(context, payload) {
      return axios.post(`${route}/move_outstanding`,payload)
    },
    getOutsatnding(context, { itemsPerPage, page, search, filter }) {
      return axios.get(`${route}/outstanding?page=${page}&items_per_page=${itemsPerPage}&search=${search}`,filter)
    }

  },
}