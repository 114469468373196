import { mdiBed, mdiBookAccount, mdiBookCheck, mdiCashRegister, mdiCog, mdiDatabasePlus, mdiFileChart, mdiMonitorDashboard, mdiSale } from '@mdi/js'


let menu = [
  {
    title: 'Dashboard',
    icon: mdiMonitorDashboard,
    to: 'dashboard',
  },
  {
    title: 'Reservas',
    icon: mdiBookAccount,
    children: [
      {
        title: 'Reservas',
        to: 'reserve',
      },
      {
        title: 'Reservas Efetuadas',
        to: 'completed_reserve',
      },
    ]
  },
  {
    title: 'Grid de Quartos',
    icon: mdiBed,
    to: 'manager_room',
  },
  {
    title: 'Adm. Hospedagens',
    icon: mdiBookCheck,
    children: [
      {
        title: 'Checkouts',
        to: 'manager_checkouts',
      },
      {
        title: 'Avulsas',
        to: 'manager_outstanding',
      },
    ]
  },
  // {
  //   title: 'PDV',
  //   icon: mdiBed,
  //   to: 'pdv',
  // },
  {
    title: 'Cadastros',
    icon: mdiDatabasePlus,
    children: [
      {
        title: 'Hóspedes',
        to: 'guest',
        dir: 'register.guest',
        action: 'index',
      },
      {
        title: 'Empresas',
        to: 'register/company',
        dir: 'register.company',
        action: 'index',
      },
      {
        title: 'Fornecedores',
        to: 'register/supply',
        dir: 'register.supply',
        action: 'index',
      },
      {
        title: 'Tarifas',
        to: 'register/tariff',
        dir: 'register.tariff',
        action: 'index',
      },
      {
        title: 'Quartos',
        to: 'register/room',
      },
      {
        title: 'Categorias de Quarto',
        to: 'register/room_category',
      },
      {
        title: 'Produtos',
        to: 'register/product',
      },
      {
        title: 'Pontos de Venda',
        to: 'register/pdv',
      },
      {
        title: 'Serviços',
        to: 'register/service',
      },
      {
        title: 'Pix',
        to: 'register/pix',
      },
      // {
      //   title: 'Cartões',
      //   to: 'register/card',
      // },

    ],
  },
  {
    title: 'Caixas',
    icon: mdiCashRegister,
    children: [
      // {
      //   title: 'Caixa',
      //   to: 'cash_open',
      // },
      {
        title: 'Lista de Caixas',
        to: 'cash_list',
      },
    ],
  },
  {
    title: 'Relatórios',
    icon: mdiFileChart,
    children: [
      {
        title: 'RDS',
        to: 'rds',
      },
      {
        title: 'Acomodações',
        children: [
          {
            title: 'Reservas',
            to: 'reserves_today',
          },
          {
            title: 'Itens Lançados',
            to: 'daily_posted_itens',
          },
          {
            title: 'Diárias Lançadas',
            to: 'daily_posted',
          },
          {
            title: 'Contas Abertas',
            to: 'report/open_account',
          },
          {
            title: 'Adiantamentos',
            to: 'report/advance_payment',
          },
          {
            title: 'Relatório de Quartos ',
            to: 'occupied_room',
          },

        ]

      },
      {
        title: 'Mapa de Ocupação',
        to: 'occupation_map',
      },
      {
        title: 'Movimentação de Ocupação',
        to: 'occupancy_movemment',
      },
     
      // { DESCONTINUADO
      //   title: 'Café da Manhã',
      //   to: 'breakfast',
      // },
    
      {
        title: 'Vendas',
        children: [
          {
            title: 'Produtos Lançados',
            to: 'report/product_posted',
          },
          {
            title: 'Produtos Vendidos',
            to: 'report/product_sale',
          },
          {
            title: 'Consumo Interno',
            to: 'intern_consumption',
          },
        ]
        ,
      },
     
      {
        title: 'Prod. Por Empresa',
        to: 'report/production_by_company',
      },
    ],
  },
  {
    title: 'Sistema',
    icon: mdiCog,
    children: [
      {
        title: 'Auditoria',
        children: [
          {
            title: 'Diárias automáticas',
            to: 'diary_execution',
          },
          {
            title: 'Auditoria',
            to: 'audit',
          },
        ],
      },
      {
        title: 'Configuração',
        to: 'config',
        dir: 'system.config',
        action: 'index',
      },
      {
        title: 'Usuários',
        to: 'user',
        dir: 'system.user',
        action: 'index',
      },
      {
        title: 'Perfis',
        to: 'role',
        dir: 'system.role',
        action: 'index',
      },
    ],
  },
]

const permissions = JSON.parse(localStorage.getItem('permissions'))

//percorre o menu verificando permissao
menu.forEach((item) => {
  if (item.children) {
    // item.children = item.children.filter((submenu)=>{
    //   return hasPermission(submenu.dir,submenu.action)
    // })

  }
})



//verifica se existe  permissao
function hasPermission(dir, action) {
  let result = permissions.find((item) => {
    return item.dir == dir && item.action == action
  })
  if (result) {
    return true
  }
  return false
}





export default menu

