import axios from '@axios'

const route = 'audit'
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getAll(context, { itemsPerPage, page}) {
      return axios.get(route, { params: { items_per_page: itemsPerPage, page: page } })
    },
    store(context) {
      return axios.post(route+'/store')
    },
    hasAudit(context) {
      return axios.get(route+'/has_audit')
    }
  },
}
